import styled, { CSSObject } from 'styled-components'
import MuiPhoneNumber from 'react-phone-input-2'
import { device } from '@theme'

export const Container = styled.div`
  position: relative;

  span {
    position: absolute;
    top: 20px;
    left: 9px;
  }

`

export const Small = styled.small`
  display: block;
  color: #970505;
  text-align: left;
  font-size: 13px;

  @media ${device.height800} {
    margin-top: 0px;
    font-size: 15px;
  }

  @media ${device.height700} {
    margin-top: 0px;
    font-size: 12px;
  }

  @media ${device.mobile} {
    margin-top: 0px;
    font-size: 10px;
  }
`

export const StyledPhoneNumber = styled(MuiPhoneNumber)(() => {
  return {
    width: '100%',
    maxWidth: '464px',
    border: '1px solid rgb(213, 223, 240)',
    borderRadius: '12px',
    height: '62px',
    display: 'flex',
    input: {
      height: '62px',
      fontFamily: 'Manrope',
      padding: '20px',
      textAlign: 'left',
      width: '100%',
      border: 'none',
      color: 'black',
      background: 'transparent',
      fontSize: '15px',
      fontStyle: 'normal',
      fontWeight: 600,
      lineHeight: 'normal',
      textTransform: 'capitalize'
    },
    '.form-control::placeholder': {
      fontWeight: 600,
      fontSize: '15px',
      color: '#959BA9'
    },
    '.form-control:focus-visible': {
      outlineWidth: 0
    },
    '& .special-label': {
      display: 'none'
    },
    '@media (max-width: 920px)': {
      maxWidth: 'unset',
      width: '100%',
      height: '48px',
      '.form-control::placeholder': {
        fontWeight: 400,
        fontSize: '14px',
        color: '#959BA9'
      },
      input: {
        fontWeight: 400,
        padding: '15px 20px',
        height: '48px',
        '::placeholder': {
          fontWeight: 400
        }
      }
    }
  } as CSSObject
})
