import styled from 'styled-components'
import { device } from '@theme'

export const RightSection = styled.div`
  height: 100%;
  width: 100%;
  position: relative;

  @media ${device.monitor} {
    padding: 0
  }
`

export const RightSectionLayerWrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  max-height: 772px;
  overflow: scroll;
  border-radius: 15.179px;

  position: relative;
  width: 100%;
  max-width: 628px;
  background: linear-gradient(333deg, #fff 1.93%, rgba(255, 255, 255, 0) 95.4%),
    rgba(150, 170, 211, 0.25);
`
export const RightItemWrappers = styled.div`
  overflow: hidden;
  position: relative;
  height: 100%;
  width: 100%;
`

export const RightScrollableContentContainer = styled.div`
  overflow: scroll;
  height: 100%;
`
export const RightSectionOverflowWrapper = styled.div`
  height: 100%;
  padding: 48px 58px;
  overflow: hidden;
`

export const ShoppersItem = styled.div`
  position: relative;
  height: 100%;
`

export const BusinessItems = styled.div`
  position: relative;
  height: 100%;
`

export const Item = styled.div`
  position: absolute;
  opacity: 0;
  padding: 15px;
  height: 100%;
  max-height: 674px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  img {
    width: 100%;
    max-height: 465px;
    object-fit: contain;
  }

  p {
    color: #262225;
    font-family: 'Manrope';
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }


  &.active {
    opacity: 1;
  }

  @media ${device.monitor} {
    p {
      color: #262225;
      font-family: 'Manrope';
      font-size: 20px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
    }
  }

  @media ${device.height800} {
    img {
      max-height: 350px;
    }
    p {
      font-size: 20px;
    }
  }

  @media ${device.height700} {
    img {
      max-height: 300px;
    }
    p {
      font-size: 18px;
    }
  }


  transition: all 1s ease 0s;
`
// export const Item = styled.div`
//   position: absolute;
//   opacity: 0;
//   height: 100%;
//   max-height: 674px;
//   gap: 20px;
//   display: flex;
//   flex-direction: column;
//   justify-content: flex-end;
//   img {
//     width: 100%;
//     max-height: 465px;
//     object-fit: contain;
//   }
//   p {
//     color: #262225;
//     font-family: 'Manrope';
//     font-size: 28px;
//     font-style: normal;
//     font-weight: 500;
//     line-height: normal;
//   }
//   &.active {
//     opacity: 1;
//   }
//   transition: all 1s ease 0s;
// `

export const ItemSpecial = styled.div`
  position: absolute;
  opacity: 0;
  height: 100%;
  gap: 20px;

  display: flex;
  align-items: end;
  flex-direction: column;
  justify-content: center;

  img {
    width: 209.514px;
    height: 200.814px;
    object-fit: contain;
  }

  p {
    color: '#151515';
    font-family: 'Manrope';
    font-size: 36px;
    font-style: normal;
    font-weight: 400;
    line-height: 125%;

    span {
      background: linear-gradient(136deg, #232BDD 0%, #F59C9C 100%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-family: 'Manrope';
      font-size: 36px;
      font-style: normal;
      font-weight: 800;
      line-height: 125%;
    }
  }


  &.active {
    opacity: 1;
  }

  @media ${device.height800} {
    p {
      font-size: 30px;
      span {
        font-size: 30px;
      }
    }

     img {
      width: 188px;
      height: 180px;
    }
  }

  @media ${device.height700} {
    p {
      font-size: 25px;

      span {
        font-size: 25px;
      }
    }

    img {
      width: 168px;
      height: 160px;
    }
  }

  @media ${device.mobile} {
    margin-top: 0px;
  }

  transition: all 1s ease 0s;
`
