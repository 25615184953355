import { useContext } from 'react'
import { nativeScroll } from '@utils/moveTo'
import { ContentTypeEnum, ScrollSectionsEnum, SectionOfStickyEnum, SizeTypeEnum, StickyScrollableContainersEnum, TriggerEnum } from '@helpers/enums'
import { MainContext } from '@context/main'

import {
  Header,
  Border,
  SectionName,
  SomeAction,
  SvgPWrapper
} from './styled'

const StickyHeader = ({ contentType }: { contentType: SectionOfStickyEnum }) => {
  const { setPopUp } = useContext(MainContext)

  return (
    <Header>
      <div className='must-be-empty-for-menu-ui' />
      <SectionName>
        <p>
          {contentType === SectionOfStickyEnum.shoppers ? 'For Shoppers' : 'For Business'}
        </p>
      </SectionName>
      <SomeAction>
        <div>
          <SvgPWrapper>
            {contentType === SectionOfStickyEnum.shoppers ?
              <p
                onClick={() => {
                  setPopUp({
                    size: SizeTypeEnum.big,
                    status: TriggerEnum.opened,
                    contentType: ContentTypeEnum.waitlist
                  })
                }}
              >
                Join the Waitlist
              </p>
              :
              <p
                onClick={() => {
                  nativeScroll(ScrollSectionsEnum.requestDemo)
                }}
              >
                Request a Demo
              </p>
            }
            <svg xmlns='http://www.w3.org/2000/svg' width='7' height='13' viewBox='0 0 7 13' fill='none'>
              <path d='M7 6.00922L7 6.97149L1.19355 12.4904L-4.20619e-08 11.5281L5.29032 6.49036L-4.82475e-07 1.45262L1.19355 0.490356L7 6.00922Z' fill='#232BDD' />
            </svg>
          </SvgPWrapper>
          <Border />
        </div>
      </SomeAction>
    </Header>
  )
}

export default StickyHeader
