import styled from 'styled-components'

import { device } from '@theme'

export const Left = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 44px;
  
  @media (max-height: 850px) {
    margin-top: 0px;
  }

  @media ${device.mobile} {
    margin-top: 0px;
    height: 100%;
    justify-content: space-between;
    align-items: center;
    /* gap: 4vh; */

    > div,
    h3 {
      height: 'min-content';
    } 
  }
`

export const LogoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: max-content;
  text-align: center;

  > svg {
    width: 100%;
    margin-bottom: 20px;
  }

  > img {
    width: 217px;
    height: 224.729px;

    @media ${device.monitor} {
      width: 177px;
      height: 184.729px;
    }

    @media ${device.tablet} {
      width: 157px;
      height: 164.729px;
    }

    @media (max-height: 850px) {
      width: 127px;
      height: 134.729px;
    }
  }

  @media (max-height: 850px) {
    > svg {
      display: block;
      margin: 0px auto 15px auto;
      width: 120px;
      height: 18px;
    }
  }

  @media ${device.mobile} {
    margin: 0 auto;

    > svg {
      display: block;
      margin: 0px auto 15px auto;
      width: 120px;
      height: 18px;
    }

    > img {
      width: 170px;
      height: 177px;
    }
  }
`

export const TitlesContainer = styled.div`
  margin-top: 50px;

  @media (max-height: 850px) {
    margin-top: 20px;
  }

  @media ${device.tablet} {
    margin-top: 10px;
    font-size: 30px;
  }

  @media ${device.mobile} {
    margin-top:0;
  }
`

export const Title = styled.h2`
  color: ${({ theme }) => theme.colors.white};
  font-family: 'Manrope';
  font-size: 48px;
  font-style: normal;
  font-weight: 500;
  line-height: 130%;

  @media ${device.monitor} {
    font-size: 38px;
  }

  @media ${device.tablet} {
    font-size: 30px;
  }

  @media ${device.mobile} {
    font-size: 24px;
    line-height: 31px;
    letter-spacing: 0em;
    text-align: center;
  }
`

export const MixWrapper = styled.div`
  margin-top: 50px;
  width: max-content;
  cursor: pointer;

  p {
    font-family: 'Manrope';
    font-style: normal;
    font-size: 24px;
    font-weight: 500;
    line-height: 130%;
    opacity: 0.5;
    margin-bottom: 12px;
    color: ${({ theme }) => theme.colors.white};
  }

  @media (max-height: 850px) {
    margin-top: 20px;
  }

  @media ${device.mobile} {
    margin-top: 0px;

    p {
      font-size: 16px;
    }

    p,
    h3 {
      text-align: center;
    }
  }
`

export const ButtonContainer = styled.div`
  button:last-child {
    margin-left: 21px;
  }

  @media ${device.mobile} {
    display: flex;
    flex-direction: column;

    button:first-child {
      color: #fff !important;
    }
    button:last-child {
      margin-left: 0;
      margin-top: 14px;
    }
  }
`

export const ScrollToExplore = styled.h3`
  font-family: 'Manrope';
  font-style: normal;
  font-size: 24px;
  font-weight: 500;
  line-height: 130%;

  opacity: 0.5;
  cursor: pointer;
  margin: 50px 0 40px 0;
  color: ${({ theme }) => theme.colors.white};

  > svg {
    margin-left: 13px;
    position: relative;
    top: 0;
    left: 0;
  }

  @media (max-height: 850px) {
    margin: 30px 0 10px 0;
  }

  @media ${device.mobile} {
    margin: 0 0 10px 0;

    font-size: 16px;
    text-align: center;

    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    font-size: 14px;

    svg {
      width: 20px;
      height: 10px;
      margin-top: 8px;
      margin-left: 0px;
    }
  }
`
