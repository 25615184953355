import styled, { css } from 'styled-components'

import { device } from '@theme'

export const multipleSectionsCss = css`
  position: relative;
  width: 100%;
  height: 100%;
`

export const multipleScrollableContentContainerCss = css`
`

export const multipleItemWrappersCss = css`
 overflow: hidden;
`

export const multipleLayerWrapper = css`
  position: absolute;
  top: 50px;
  width: 100%;
  height: 90vh;
  max-height: 772px;
  overflow: scroll;
  border-radius: 15.179px;
`

export const Container = styled.div`
  position: relative;
  height: 100%;
  width: 100%;
`

export const Content = styled.div`
  position: relative;
  margin: 0px auto;
  max-height: 887px;
  max-width: 1440px;
  padding: 50px 0px 50px 80px;
  display: grid;
  height: 100%;
  grid-template-columns: 1fr 100px minmax(50%, 628px);
  
  @media ${device.monitor} {
    padding: 30px 30px 30px 30px;
    grid-template-columns: 1fr 100px 1fr;
  }

  @media ${device.height800} {
   grid-template-columns: 6fr 100px 5fr;
  }

  @media ${device.laptop} {
    padding: 30px 30px 30px 30px;
    grid-template-columns: 3fr 70px 2fr;
  }

  @media ${device.height700} {
   grid-template-columns: 6fr 70px 5fr;
  }

`

export const LeftSectionWrapper = styled.div`
  max-height: 772px;
`
export const MiddleSectionWrapper = styled.div`
  max-height: 772px;
  position: relative;
`
export const RightSectionWrapper = styled.div`
  max-height: 772px;
`
