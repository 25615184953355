import styled from 'styled-components'

import { device } from '@theme'

export const Container = styled.div`
  > div {
    h4 {
      color: #151515;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 126%;
      margin: 18px 68px auto 68px;

      &.unic-h4 {
        color: #959ba9;
        margin-top: 85px;
        margin-bottom: 0;
      }

      @media ${device.mobile} {
        margin: 18px 52px auto 52px;
      }
    }
  }
`

export const Title = styled.h4`
  cursor: pointer;
  color: #151515;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 126%;
  margin: 26px 68px;

  &:first-of-type {
    margin-top: 55px !important;
  }

  @media ${device.mobile} {
    margin: 26px 52px;
  }
`
