import { memo, useContext } from 'react'

import { nativeScroll } from '@utils/moveTo'
import { StickySubSection } from '@helpers/types'
import {
  ContentTypeEnum,
  TriggerEnum,
  SizeTypeEnum,
  SectionOfStickyEnum,
  ScrollSectionsEnum,
  StickyScrollableContainersEnum
} from '@helpers/enums'
import { MainContext } from '@context/main'
import { forBusinessStaticData, forShopperStaticData } from '@Components/StickyDesktop/data'
import Button, { ButtonTypeEnum } from '@Components/Button'
import TopSwitcherSticky from '../TopSwitcherSticky'

import {
  LeftSection as StyledLeftSection,
  Title,
  SwitcherItems,
  LeftItemWrappers,
  SmallNavigationContainer,
  SwitcherItemsContainer,
  LeftSectionLayerWrapper,
  LeftSectionOverflowWrapper,
  LeftScrollableContentContainer
} from './styled'

const LeftSection = ({ top, contentType }: { top: number, contentType: SectionOfStickyEnum }) => {
  const { setPopUp, shopperState, setShopperState, businessState, setBusinessState } = useContext(MainContext)
  const order = (contentType === SectionOfStickyEnum.shoppers ? shopperState.subSection : businessState.subSection) as StickySubSection

  return (
    <StyledLeftSection>
      <LeftSectionLayerWrapper>
        <TopSwitcherSticky contentType={contentType} />
        <LeftSectionOverflowWrapper>
          <LeftScrollableContentContainer id={StickyScrollableContainersEnum.stickyLeftContainer}>
            {
              contentType === SectionOfStickyEnum.shoppers ? (
                <LeftItemWrappers id={ScrollSectionsEnum.shoppers}>
                  <Title>
                    {'Find your favourite \ndeals and businesses.\n All in one place.'}
                  </Title>
                  <div className='button-wrapper'>
                    <Button
                      buttonType={ButtonTypeEnum.thirdly}
                      onClick={() => {
                        setPopUp({
                          size: SizeTypeEnum.big,
                          status: TriggerEnum.opened,
                          contentType: ContentTypeEnum.waitlist
                        })
                      }}
                    >
                      Join The Waitlist
                    </Button>
                  </div>
                  <div className='switcher-wrapper'>
                    <SmallNavigationContainer id='parent'>
                      <SwitcherItemsContainer order={order} top={top}>
                        {forShopperStaticData.map(((item) => {
                          return (
                            <SwitcherItems
                              key={item?.id}
                              isActive={shopperState.subSection === item.id ? 'active' : ''}
                              onClick={() => {
                                setShopperState({ subSection: +item.id })
                              }}
                            >
                              {item.name}
                            </SwitcherItems>
                          )
                        }))}
                      </SwitcherItemsContainer>
                    </SmallNavigationContainer>
                  </div>
                </LeftItemWrappers>
              ) : (
                <LeftItemWrappers id={ScrollSectionsEnum.business}>
                  <Title>
                    {businessState.subSection === 4 ? 'Ready to save\n some bucks?' : 'Attract more customers\n and increase your foot\n traffic with Saving Bucks.'}
                  </Title>
                  <div className='button-wrapper'>
                    <Button
                      buttonType={ButtonTypeEnum.thirdly}
                      onClick={() => {
                        nativeScroll(ScrollSectionsEnum.requestDemo)
                        setShopperState({ subSection: 0 })
                        setBusinessState({ subSection: 0 })
                      }}
                    >
                      Request a Demo
                    </Button>
                  </div>
                  <SmallNavigationContainer>
                    <SwitcherItemsContainer order={order} top={top}>
                      {forBusinessStaticData.map(((item) => {
                        return (
                          <SwitcherItems
                            key={item.id}
                            isActive={businessState.subSection === item.id ? 'active' : ''}
                            onClick={() => {
                              setBusinessState({ subSection: item.id })
                            }}
                          >
                            {item.name}
                          </SwitcherItems>
                        )
                      }))}
                    </SwitcherItemsContainer>
                  </SmallNavigationContainer>
                </LeftItemWrappers>
              )
            }
          </LeftScrollableContentContainer>
        </LeftSectionOverflowWrapper>
      </LeftSectionLayerWrapper>
    </StyledLeftSection>
  )
}

export default memo(LeftSection)
