import styled from 'styled-components'

export const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`

export const OverflowWrapper = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: start;
`

export const Titles = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  min-height: 93px;
  max-height: 93px;
  margin: 20px 0px 19px;
`

export const TitlesSubContainer = styled.div`
  max-width: 90vw;
  height: 93px;
  margin: 0px auto;
`

export const Layer = styled.div`
  position: absolute;
  z-index: 9;
  width: 100%;
  height: 100%;
  background: transparent;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`

export const H2 = styled.h2`
  white-space: pre-line;
  padding: 0 20px 0 0px;
  height: 93px;
  color: #959BA9;
  font-family: 'Manrope';
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  line-height: 129.5%;
`

export const P = styled.p`
  color: #959BA9;
  font-family: 'Manrope';
  font-size: 24px;
  font-weight: 500;
  line-height: 31px;
  letter-spacing: 0em;
  text-align: left;
`
export const GradientContainer = styled.div`
  position: relative;
  width: 100%;
  max-width: 90vw;
  height: 400px; // 460
  padding: 20px 0px 40px 0px;
  border-radius: 7.530512809753418px;
  background: linear-gradient(333deg, #fff 1.93%, rgba(255, 255, 255, 0) 95.4%), rgba(150, 170, 211, 0.25);
`

export const GradientContainerOverflow = styled.div`
  overflow: scroll;
  height: 100%;     
`

export const ScrollBusiness = styled.div`
  margin-top: 30px;
  text-align: center;

  p {
    color: #000;
    opacity: 0.2;
    font-family: 'Manrope';
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
  }

  svg {
    margin-top: 8px;
  }
`

export const ShoppersBusiness = styled.div`
  scroll-snap-align: start;
  position: relative;
  height: 400px;
  width: 100%;
  overflow: hidden;

  .swiper.swiper-initialized {
    height: 100%;

    > .swiper-wrapper {
      > .swiper-slide {
        padding: 0px 20px;

        > div {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
        }
      }
    }
  }
`

export const Item = styled.div`
  gap: 16px;

  img {
    height: 80%;
    width: 100%;
    max-height: 200px;
    object-fit: contain;

    &.special-style {
      height: 100% !important;
      /* max-height: 300px !important; */
      object-fit: cover !important;
    }
  }

  p {
    color: #262225;
    font-family: 'Manrope';
    font-size: 14px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0em;
    text-align: left;
    margin-bottom: 15px;
  }

  transition: 0.5s;

  &.active {
    gap: 16px;
    opacity: 1;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    transition: all 0.5s ease 0s;

    img {
      width: 100%;
      max-height: 200px;
      object-fit: contain;
    }

    transition: 0.5s;
  }
`

export const ItemSpecial = styled.div`
  gap: 16px;
  display: flex;
  align-items: end;
  flex-direction: column;
  justify-content: center;
  padding-top: 70px;

  img {
    width: 109.514px;
    height: 100.814px;
    object-fit: contain;
  }

  p {
    color: '#151515';
    font-family: 'Manrope';
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 125%;

    span {
      background: linear-gradient(136deg, #232BDD 0%, #F59C9C 100%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-family: 'Manrope';
      font-size: 24px;
      font-style: normal;
      font-weight: 800;
      line-height: 125%;
    }
  }

  &.active {
    opacity: 1;
  }

  .last-item-mobile {
    margin-bottom: 40px;
  }
`
