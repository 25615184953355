import { forwardRef } from 'react'
import { UseFormRegisterReturn } from 'react-hook-form'

import { StyledInput, Small } from './styled'

export enum InputTypeEnum {
  primary = 'primary',
  secondary = 'secondary',
  thirdly = 'thirdly'
}

interface IInput extends UseFormRegisterReturn {
  error: string
  placeholder: string
}

const Input = forwardRef((props: IInput, ref: any) => {
  const { error, ...rest } = props

  return (
    <div>
      <StyledInput {...rest} ref={ref} />
      {error && <Small>{error}</Small>}
    </div>
  )
})

export default Input
