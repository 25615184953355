import { useContext } from 'react'

import { nativeScroll } from '@utils/moveTo'
import {
  SizeTypeEnum,
  ContentTypeEnum,
  ScrollSectionsEnum,
  SectionOfStickyEnum,
  TriggerEnum
} from '@helpers/enums'
import { MainContext } from '@context/main'
import { Container, Title } from './styled'

const NavigationContent = () => {
  const { setPopUp, setBusinessState, setShopperState } = useContext(MainContext)

  const handleSwitch = () => {
    setPopUp((prev) => ({ ...prev, size: SizeTypeEnum.big, contentType: ContentTypeEnum.waitlist }))
  }

  return (
    <Container>
      <Title
        onClick={() => {
          setShopperState({ subSection: 0 })
          setPopUp((prev) => ({ ...prev, status: TriggerEnum.closed }))
          setTimeout(() => {
            nativeScroll(ScrollSectionsEnum.shoppers)
          }, 500)
        }}
      >
        For Shoppers
      </Title>
      <Title
        onClick={() => {
          setBusinessState({ subSection: 0 })
          setPopUp((prev) => ({ ...prev, status: TriggerEnum.closed }))
          setTimeout(() => {
            nativeScroll(ScrollSectionsEnum.business)
          }, 500)
        }}
      >
        For Business
      </Title>
      <Title
        onClick={() => {
          setShopperState({ subSection: 0 })
          setBusinessState({ subSection: 0 })
          setPopUp((prev) => ({ ...prev, status: TriggerEnum.closed }))
          setTimeout(() => {
            nativeScroll(ScrollSectionsEnum.requestDemo)
          }, 500)
        }}
      >
        Request a Demo
      </Title>
      <Title onClick={handleSwitch}>Join Waitlist</Title>

      <div>
        <Title className='unic-h4'>Follow us on: </Title>
        <Title>Instagram</Title>
        <Title>Facebook</Title>
      </div>
    </Container>
  )
}

export default NavigationContent
