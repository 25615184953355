import styled, { css } from 'styled-components'
import { device } from '@theme'
import { StickySubSection } from '@helpers/types'
import { multipleSectionsCss } from '../../styled'

export const LeftSection = styled.div`
  ${multipleSectionsCss};
  overflow: hidden;
`

export const LeftSectionLayerWrapper = styled.div`
  width: 100%;
  height: 100%;
  max-height: 772px;
  overflow: hidden;
  border-radius: 15.179px;
`

export const LeftSectionOverflowWrapper = styled.div`
  height: 100%;
  padding: 80px 0px 0px 0;
  overflow: hidden;
`

export const LeftScrollableContentContainer = styled.div`
  overflow: hidden;
  height: 100%;
`

export const LeftItemWrappers = styled.div`
  position: relative;
  height: 100%;
  max-height: 772px;
  width: 100%;

  .button-wrapper {
    position: relative;

    button {
      position: absolute;
      z-index: 51;
      margin-top: 38px;
    }
  }
`

// ####################################

export const SwitcherItemsContainer = styled.div<{ order: StickySubSection, top?: number }>`
  position: absolute;
  right: 0;
  max-width: 304px;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 15px;
  ${({ top, order }) => {
    const result = order < 4 ? 24 : 24 + (order * (order / 2.5))

    return css`
      bottom: ${`calc(${top}% + ${result}px)`};
    `
  }};

  transition: all 0.5s ease 0s;
`

export const SwitcherItems = styled('p')<{ isActive: 'active' | '' }>(({ isActive }) => {
  if (isActive === 'active') {
    return css`
      color: #232BDD;
      text-align: right;
      font-family: 'Manrope';
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      text-transform: capitalize;
      transition: 1s ease all;
      cursor: pointer;
    `
  }

  return css`
    color: #959BA9;
    text-align: right;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: capitalize;
    transition: 1s ease all;
    cursor: pointer;
  `
})

export const Title = styled.h2`
  color: #959BA9;
  font-family: 'Manrope';
  font-size: 48px;
  font-style: normal;
  font-weight: 500;
  line-height: 62px;
  text-align: left;
  white-space: pre-line;

  @media ${device.monitor} {
    font-size: 40px;
    line-height: initial !important;
  }
  
  @media ${device.height800} {
    font-size: 40px;
    line-height: initial !important;
  }

  @media ${device.laptop} {
    font-size: 30px;
    line-height: initial !important;
  }

  @media ${device.height700} {
    font-size: 30px;
    line-height: initial !important;
  }
`

export const SmallNavigationContainer = styled.div`
  position: absolute;
  min-height: 225px;
  width: 100%;
  bottom: 0px;
  right: 0px;
  display: flex;
  justify-content: flex-end;
`

export const CoordinateElement = styled.span`
  position: absolute;
  width: 20px;
  height: 20px;
  top: 0;
  top: 50%;
  left: 50%;
  transform: translate(-327%, -50%);
`

export const BorderGradientContainer = styled('div')<{
  borderSvgPosition: string
}>(({ borderSvgPosition }) => {
  return css`
    position: absolute;
    top: 0px;
    right: 0px;
    width: 90px;
    height: 100%;
    max-height: 772px;
    left: 15px;

    display: flex;
    justify-content: center;
    align-items: flex-end;

    div {
      position: relative;
      bottom: ${borderSvgPosition};
    }

    svg {
      height: 225px;
      width: 90px;
    }


    @media ${device.height700} {
      width: 70px;
      left: 0px;

      svg {
        height: 225px;
        width: 70px;
      }
    }

    @media ${device.laptop} {
      left: 0px;
      width: 70px;

      svg {
        width: 70px;
      }
    }

  `
})
