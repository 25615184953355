import { useContext } from 'react'

import { utilClosingPopUp } from '@utils/updatePopUp'
import { TriggerEnum } from '@helpers/enums'
import { MainContext } from '@context/main'
import Button, { ButtonTypeEnum } from '@Components/Button'

import { Container } from './styled'

const ThankYou = () => {
  const { setPopUp } = useContext(MainContext)

  return (
    <Container>
      <h2>Thank you!</h2>
      <p>You have been added to the waitlist. We will keep you posted!</p>
      <Button
        buttonType={ButtonTypeEnum.fourth}
        onClick={() => {
          utilClosingPopUp({
            closingSidebarWithDelayForChangeContent: () => {
              setPopUp((prev) => ({ ...prev, status: TriggerEnum.closed }))
            },
            setPopUp
          })
        }}
      >
        Dismiss
      </Button>
    </Container>
  )
}

export default ThankYou
