import { SectionOfStickyEnum } from '@helpers/enums'
import { useStickySection } from '@Components/StickyDesktop/useStickySection'
import { ReactComponent as BorderGradientSvg } from '@assets/svg/border-with-gradient.svg'
import RightSection from '../RightSection'
import { BorderGradientContainer, CoordinateElement } from '../LeftSection/styled'
import LeftSection from '../LeftSection'
import {
  Content as StyledContent,
  LeftSectionWrapper,
  MiddleSectionWrapper,
  RightSectionWrapper
} from '../../styled'

const Content = ({ contentType }: { contentType: SectionOfStickyEnum }) => {
  const { top, borderSvgPosition } = useStickySection({ contentType })

  return (
    <StyledContent>
      <LeftSectionWrapper>
        <LeftSection top={top} contentType={contentType} />
      </LeftSectionWrapper>
      <MiddleSectionWrapper>
        <BorderGradientContainer borderSvgPosition={borderSvgPosition}>
          <div>
            <CoordinateElement id='transparent-box' />
            <BorderGradientSvg />
          </div>
        </BorderGradientContainer>
      </MiddleSectionWrapper>
      <RightSectionWrapper>
        <RightSection contentType={contentType} />
      </RightSectionWrapper>
    </StyledContent>
  )
}

export default Content
