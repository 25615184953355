import { memo } from 'react'

import { StyledButton } from './styled'

export enum ButtonTypeEnum {
  primary = 'primary',
  secondary = 'secondary',
  thirdly = 'thirdly',
  fourth = 'fourth'
}

interface IButton {
  buttonType?: ButtonTypeEnum
  children: React.ReactNode | string
  onClick?: () => void
}

const Button = ({ buttonType = ButtonTypeEnum.primary, children, ...rest }: IButton) => {
  return (
    <StyledButton buttonType={buttonType} {...rest}>
      {children}
    </StyledButton>
  )
}

export default memo(Button)
