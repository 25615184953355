import styled, { css } from 'styled-components'
import { device } from '@theme'
import { ContentTypeEnum, SizeTypeEnum, TriggerEnum } from '@helpers/enums'

const animationSecond = '0.5s'

const layerBasePosition = css`
  position: fixed;
  right: 0;
  left: 0;
  bottom: 0;
  top: 0;
  height: 100%;
  width: 0vw;
  background: rgba(0, 0, 0, 0.2);
`

const layerDonePosition = css`
  ${layerBasePosition};
  width: 100vw;
  z-index: 100;
  transition: ${animationSecond};
  transition: width 0ms;
`

const layerInitialPosition = css`
  ${layerBasePosition};
  z-index: 100;
  transition: ${animationSecond};

  transition-delay: ${animationSecond};
  transition-property: z-index;
`

export const Container = styled('div')<{ status: TriggerEnum }>(({ status }) => {
  if (status === TriggerEnum.closed) {
    return layerInitialPosition
  }

  return layerDonePosition
})

// #################################################################

interface ISideBarContent {
  status: TriggerEnum
  size: SizeTypeEnum
  contentWidth: number
  isMobile: boolean
}

export const SideBarContent = styled('div')<ISideBarContent>(
  ({ size, status, contentWidth, isMobile }) => {
    const screenWidth = window.innerWidth
    const emptySpaceOfSides = screenWidth - contentWidth

    const sideBarContentSizes = {
      mobile: {
        small: 288,
        big: 375
      },
      desktop: {
        small: 323,
        big: 592
      }
    }

    const getBySize = isMobile ? sideBarContentSizes.mobile : sideBarContentSizes.desktop
    const designedWidth = getBySize[size]
    const result = designedWidth

    let calculated = `calc(100vw - ${result}px)`

    if (isMobile && size === SizeTypeEnum.big) {
      calculated = '0vw'
    }
    // else if (isMobile && size === SizeTypeEnum.small) {
    //   calculated = 'calc(0vw + 47px)'
    // }

    return css`
      display: flex;
      justify-content: end;
      position: relative;
      top: 0;
      right: ${status === TriggerEnum.closed ? '100vw' : calculated};
      width: 100vw;
      height: 100%;
      background: ${({ theme }) => theme.colors.white};
      transition: ${animationSecond};
    `
  }
)

export const Content = styled('div')<{ isMobile: boolean; contentType: ContentTypeEnum }>(
  ({ isMobile, contentType }) => {
    const widthsDesktop = {
      [ContentTypeEnum.initial]: '320px',
      [ContentTypeEnum.waitlist]: '592px',
      [ContentTypeEnum.thankYou]: '592px'
    }

    const widthsMobile = {
      [ContentTypeEnum.initial]: '288px',
      [ContentTypeEnum.waitlist]: '100%',
      [ContentTypeEnum.thankYou]: '100%'
    }

    let mobile = css`
      @media ${device.mobile} {
        img {
          width: 137px;
          height: 142px;
          margin: 61px 95px 0px 52px;
        }
      }
    `

    if (isMobile && contentType !== ContentTypeEnum.initial) {
      mobile = css`
        img {
          display: none;
        }
      `
    }

    return css`
      img {
        margin: 61px 95px 0px 68px;

        @media ${device.height800} {
          margin: 31px 95px 0px 38px;
        }
        @media ${device.height600} {
          margin: 20px 95px 0px 20px;
        }
      }

      width: ${!isMobile ? widthsDesktop[contentType] : widthsMobile[contentType]};
      ${mobile}
    `
  }
)
