import { memo, useContext, useEffect, useRef } from 'react'

import { utilClosingPopUp } from '@utils/updatePopUp'
import useIsMobile from '@hooks/useIsMobile'
import { ContentTypeEnum, TriggerEnum } from '@helpers/enums'
import { MainContext } from '@context/main'
import logoBlue from '@assets/images/logoBlue.png'

import Header from '../Header'
import { Container, Content, SideBarContent } from './styled'
import { JoinWaitlist, NavigationContent, ThankYou } from './components'

const SideBar = () => {
  const containerRef = useRef(null)
  const { isMobile } = useIsMobile()
  const { contentWidth, popUp, setPopUp } = useContext(MainContext)

  const handleDetectClick = (event: MouseEvent) => {
    if (event.srcElement === containerRef.current) {
      utilClosingPopUp({
        closingSidebarWithDelayForChangeContent: () => {
          setPopUp((prev) => ({ ...prev, status: TriggerEnum.closed }))
        },
        setPopUp
      })
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleDetectClick)

    return () => {
      window.removeEventListener('click', handleDetectClick)
    }
  }, [])

  const Switcher = () => {
    switch (popUp.contentType) {
      case ContentTypeEnum.initial:
        return <NavigationContent />
      case ContentTypeEnum.waitlist:
        return <JoinWaitlist />
      case ContentTypeEnum.thankYou:
        return <ThankYou />
      default:
        return null
    }
  }

  return (
    <Container status={popUp.status} ref={containerRef}>
      <SideBarContent
        size={popUp.size}
        isMobile={isMobile}
        status={popUp.status}
        contentWidth={contentWidth}
      >
        <Header isMobile={isMobile} size={popUp.size} />
        <Content isMobile={isMobile} contentType={popUp.contentType}>
          <img src={logoBlue} alt='blue kind of logo' width={157} height={163} />
          <Switcher />
        </Content>
      </SideBarContent>
    </Container>
  )
}

export default memo(SideBar)
