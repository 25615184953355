import { forwardRef } from 'react'
import { ScrollSectionsEnum } from '@helpers/enums'

import { ContainerAndContentMiddle, Content, RequestDemoContainer, PageAndImageWrapper } from './styled'
import RightSide from './components/RightSide'
import LeftSide from './components/LeftSide'

const RequestDemo = (_props: any, ref: any) => {
  return (
    <PageAndImageWrapper
      ref={ref}
      id={ScrollSectionsEnum.requestDemo}
    >
      <RequestDemoContainer>
        <ContainerAndContentMiddle>
          <Content>
            <LeftSide />
            <RightSide />
          </Content>
        </ContainerAndContentMiddle>
      </RequestDemoContainer>
    </PageAndImageWrapper>
  )
}

export default forwardRef(RequestDemo)
