import styled from 'styled-components'

import { device } from '@theme'

export const PageAndImageWrapper = styled.div`
  height: calc(100% - 50px);
`

export const ContainerAndContentMiddle = styled.div`
  height: 100%;
  /* min-height: 877px; */
  display: flex;

  @media ${device.mobile} {
    min-height: unset;
  }
`

export const HomeContainer = styled.div`
  position: relative;
  z-index: 3;
  height: 100%;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;

  > img {
    position: absolute;
  }
`

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  height: 100%;
  max-height: 877px;
  margin-top: 70px;
  width: 100%;
  padding: 0px 128px;

  @media (max-height: 800px) {
    margin-top: 10vh;
  }

  @media ${device.monitor} {
    padding: 0px 5vw;
  }

  @media ${device.tablet} {
    padding: 0px 3vw;
  }

  @media ${device.mobile} {
    margin-top: 50px;
    padding: 0px 24px;
    justify-content: center;
  }
`
