import { createContext, Dispatch, SetStateAction, useState } from 'react'
import { IPopUp, StickyParamsType, StickySubSectionState } from '@helpers/types'
import { ContentTypeEnum, HamburgerTypeEnum, SizeTypeEnum, TriggerEnum, SectionOfStickyEnum } from '@helpers/enums'

interface IContext {
  contentWidth: number
  setContentWidth: Dispatch<SetStateAction<number>>
  popUp: IPopUp
  setPopUp: Dispatch<SetStateAction<IPopUp>>
  menuType: HamburgerTypeEnum
  setMenuType: Dispatch<SetStateAction<HamburgerTypeEnum>>
  // stickyParams: StickyParamsType
  shopperState: StickySubSectionState,
  setShopperState: Dispatch<SetStateAction<StickySubSectionState>>,
  businessState: StickySubSectionState,
  setBusinessState: Dispatch<SetStateAction<StickySubSectionState>>,
}

export const MainContext = createContext({} as IContext)

const initialPopUpValue = {
  status: TriggerEnum.closed,
  size: SizeTypeEnum.small,
  contentType: ContentTypeEnum.initial
}

const ContextWrapper = ({ children }: { children: React.ReactNode }) => {
  const [popUp, setPopUp] = useState<IPopUp>(initialPopUpValue)
  const [contentWidth, setContentWidth] = useState<number>(0)
  const [menuType, setMenuType] = useState<HamburgerTypeEnum>(HamburgerTypeEnum.primary)
  const [shopperState, setShopperState] = useState<StickySubSectionState>({ subSection: 0 })
  const [businessState, setBusinessState] = useState<StickySubSectionState>({ subSection: 0 })

  return (
    <MainContext.Provider
      value={{
        contentWidth,
        setContentWidth,
        popUp,
        setPopUp,
        menuType,
        setMenuType,
        shopperState,
        setShopperState,
        businessState,
        setBusinessState
      }}
    >
      {children}
    </MainContext.Provider>
  )
}

export default ContextWrapper
