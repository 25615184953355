import styled from 'styled-components'

import { device } from '@theme'

export const Right = styled.div`
  > img {
    width: auto; // 460px;
    height: 90vh;
    max-height: 737px;
  }

  @media ${device.monitor} {
    > img {
      height: 80vh;
    }
  }

  @media ${device.mobile} {
    display: none;
  }
`
