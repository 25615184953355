import { useEffect, useState, useContext } from 'react'

import { SectionOfStickyEnum, StickyScrollableContainersEnum } from '@helpers/enums'
import { MainContext } from '@context/main'

export const useStickySection = ({ contentType }: { contentType: SectionOfStickyEnum }) => {
  const { businessState, shopperState } = useContext(MainContext)
  const [svgPosition, setSvgPosition] = useState<string>('10%')

  useEffect(() => {
    if (!businessState.subSection || !shopperState.subSection) {
      const elem = document.getElementById('border-gradient-container')
      const params = elem?.getBoundingClientRect()

      const containerHeight = params?.height as number
      const elementHeight = 225
      const percentage = (elementHeight / containerHeight) * 100
      const localCalculated = `${percentage / 2}%`

      setSvgPosition(localCalculated)
    }
  }, [businessState.subSection, shopperState.subSection])

  const subSectionValue = contentType === SectionOfStickyEnum.shoppers ? shopperState.subSection : businessState.subSection

  let result = 0
  if (subSectionValue) {
    if (subSectionValue < 5) {
      result = (subSectionValue * 20)
    } else if (subSectionValue > 4) {
      result = ((subSectionValue - 5) * 20)
    }
  }

  return {
    top: result,
    borderSvgPosition: svgPosition
  }
}
