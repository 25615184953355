import styled, { css } from 'styled-components'

import { device } from '@theme'
import { SizeTypeEnum, TriggerEnum } from '@helpers/enums'

interface ISideBarContent {
  isMobile: boolean
  size: SizeTypeEnum
  status?: TriggerEnum
  contentWidth: number
  windowWidth: number
}

const svg = css`
  svg {
    position: absolute;
    cursor: pointer;
    top: 0px;
    right: 0px;
    z-index: 5555;
    width: 72px;
    height: 70px;

    @media ${device.mobile} {
      width: 47px;
      height: 45px;
    }
  }
`

export const Header = styled('div')<ISideBarContent>(
  ({
    size,
    status,
    isMobile,
    windowWidth,
    contentWidth
  }) => {
    const screenWidth = windowWidth
    const emptySpaceOfSides = screenWidth - contentWidth

    let result = isMobile ? '-47px' : '-71px'

    if (emptySpaceOfSides > 0) {
      result = `-${emptySpaceOfSides / 2 + (isMobile ? 47 : 71)}px`
    }

    let openedDynamicRight = isMobile ? '-46px' : '-71px'

    if (isMobile && size === SizeTypeEnum.big) {
      openedDynamicRight = '-1px'
    }

    if (status === TriggerEnum.opened) {
      return css`
        ${svg};
        position: absolute;
        top: 0px;
        right: ${openedDynamicRight};
        transition: right 0.36s;
        transition-delay: right 3s;
        
        .open-icon {
          opacity: 0;
          transition: opacity .1s;
          transition-delay: 0;
        }
        .close-icon {
          opacity: 1;
          transition: opacity .2s;
          transition-delay: .1;
        }
      `
    }

    return css`
      ${svg};
      position: absolute;
      top: 0px;
      right: -71px;
      transition: right 0.4s;
      transition-delay: right 3s;

      .open-icon {
        opacity: 1;
        transition: opacity .3s;
        transition-delay: .7s;
      }
      .close-icon {
        opacity: 0;
        transition: opacity .3s;
        transition-delay: .7s;
      }

      @media ${device.mobile} {
        right: -47px;
      }
    `
  }
)
