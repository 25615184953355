import { Dispatch, SetStateAction } from 'react'

import { IPopUp } from '@helpers/types'
import { ContentTypeEnum, SizeTypeEnum } from '@helpers/enums'

interface IUseUpdatePopUp {
  closingSidebarWithDelayForChangeContent: () => void
  setPopUp: Dispatch<SetStateAction<IPopUp>>
}

export const utilClosingPopUp = ({
  closingSidebarWithDelayForChangeContent,
  setPopUp
}: IUseUpdatePopUp) => {
  closingSidebarWithDelayForChangeContent()

  setTimeout(() => {
    setPopUp((prev) => ({
      ...prev,
      contentType: ContentTypeEnum.initial,
      size: SizeTypeEnum.small
    }))
  }, 500)
}
