import { useEffect } from 'react'

import RequestDemo from '@pages/RequestDemo'
import Home from '@pages/Home'
import useIsMobile from '@hooks/useIsMobile'

import { SectionOfStickyEnum, StickyScrollableContainersEnum } from '@helpers/enums'
import backgroundUniversal from '@assets/backgrounds/universal.webp'
import MobileShoppers from '../StickyMobile/MobileShoppers'
import MobileBusiness from '../StickyMobile/MobileBusiness'
import DesktopStickyContent from '../StickyDesktop/components/Content'
import { useScroll } from './useScroll'
import { PageContainer, MainContentsWrapper } from './styled'

const Landing = () => {
  const { isMobile } = useIsMobile()
  const { opacityHome, mainContainerRef, homeContainerRef } = useScroll()

  return (
    <PageContainer
      ref={mainContainerRef}
      id={StickyScrollableContainersEnum.main}
    >
      <div className='gradients-wrapper'>
        <div className='desktop-gradients-first' />
        <div className='desktop-gradients-second' />
        <div className='desktop-gradients-third' />
      </div>

      <MainContentsWrapper>
        <img
          src={backgroundUniversal}
          alt='background'
          style={{
            position: 'absolute',
            willChange: 'opacity',
            width: '100vw',
            height: '100%',
            left: '0',
            top: '0',
            zIndex: '2',
            opacity: opacityHome
          }}
        />
        <Home ref={homeContainerRef} />
        <div className='top-blue-gradient-desktop active' />
        <div className='top-blue-gradient-mobile-wrapper active'>
          <div className='gradient' />
        </div>
      </MainContentsWrapper>
      {
        isMobile ? (
          <>
            <MainContentsWrapper>
              <img
                src={backgroundUniversal}
                alt='background'
                style={{
                  position: 'absolute',
                  willChange: 'opacity',
                  width: '100vw',
                  height: '100%',
                  left: '0',
                  top: '0',
                  zIndex: '-1',
                  opacity: opacityHome
                }}
              />
              <MobileShoppers
                contentType={SectionOfStickyEnum.shoppers}
              />
            </MainContentsWrapper>
            <MainContentsWrapper>
              <img
                src={backgroundUniversal}
                alt='background'
                style={{
                  position: 'absolute',
                  willChange: 'opacity',
                  width: '100vw',
                  height: '100%',
                  left: '0',
                  top: '0',
                  zIndex: '-1',
                  opacity: opacityHome
                }}
              />
              <MobileBusiness
                contentType={SectionOfStickyEnum.business}
              />
            </MainContentsWrapper>
          </>
        ) : (
          <>
            <MainContentsWrapper>
              <img
                src={backgroundUniversal}
                alt='background'
                style={{
                  position: 'absolute',
                  willChange: 'opacity',
                  width: '100vw',
                  height: '100%',
                  left: '0',
                  top: '0',
                  zIndex: '-1',
                  opacity: opacityHome
                }}
              />
              <DesktopStickyContent contentType={SectionOfStickyEnum.shoppers} />
            </MainContentsWrapper>
            <MainContentsWrapper>
              <img
                src={backgroundUniversal}
                alt='background'
                style={{
                  position: 'absolute',
                  willChange: 'opacity',
                  width: '100vw',
                  height: '100%',
                  left: '0',
                  top: '0',
                  zIndex: '-1',
                  opacity: opacityHome
                }}
              />
              <DesktopStickyContent contentType={SectionOfStickyEnum.business} />
            </MainContentsWrapper>
          </>
        )
      }

      <MainContentsWrapper>
        <img
          src={backgroundUniversal}
          alt='background'
          style={{
            position: 'absolute',
            willChange: 'opacity',
            width: '100vw',
            height: '100%',
            left: '0',
            top: '0',
            zIndex: '-1',
            opacity: opacityHome
          }}
        />
        <RequestDemo />
      </MainContentsWrapper>

    </PageContainer>
  )
}

export default Landing
