/* eslint-disable no-useless-escape */
import { useState } from 'react'
import Select, { SingleValue } from 'react-select'
import { useForm } from 'react-hook-form'

import { nativeScroll } from '@utils/moveTo'
import useIsMobile from '@hooks/useIsMobile'
import { ScrollSectionsEnum } from '@helpers/enums'
import PhoneInput from '@Components/PhoneInput'
import { Small } from '@Components/Input/styled'
import Input from '@Components/Input'
import Button from '@Components/Button'
import { ReactComponent as ArrowTop } from '@assets/svg/arrow-top.svg'

import { Right, Content, ThankContent, Title, SubTitle, Form, Inputs, SelectWrapper } from './styled'

const WEBSITE = /^(?:(?:(?:https?|ftp):\/\/)|(?:www\.)|(?:www|http|https):\/\/)(?:\S+(?::\S*)?@|\d{1,3}(?:\.\d{1,3}){3}|(?:(?:[a-zA-Z\d\u00a1-\uffff]+-?)*[a-zA-Z\d\u00a1-\uffff]+)(?:\.(?:[a-zA-Z\d\u00a1-\uffff]+-?)*[a-zA-Z\d\u00a1-\uffff]+)*(?:\.[a-zA-Z\u00a1-\uffff]{2,6}))(?::\d+)?(?:[^\s]*)?$/i
const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i
const PHONE_REGEX = /^(1\s?)?(\d{3}|\(\d{3}\))[\s\-]?\d{3}[\s\-]?\d{4}$/

type IOption = { value: string; label: string }

export interface IFormStat {
  fullname: string,
  email: string,
  phone: string,
  website: string,
  businessSize: string,
}

const RightSide = () => {
  const [isDone, setIsDone] = useState(false)
  const [option, setOption] = useState<SingleValue<IOption>>()
  const { isMobile } = useIsMobile()
  const options = [
    { type: 'hidden', value: '', label: 'Business size', isDisabled: true },
    { value: '1 to 9 employees', label: '1 to 9 employees' },
    { value: '10 to 49 employees', label: '10 to 49 employees' },
    { value: '50 to 249 employees', label: '50 to 249 employees' },
    { value: '250 employees or more', label: '250 employees or more' }
  ]

  const {
    register,
    handleSubmit,
    setValue,
    trigger,
    watch,
    setError,
    formState: { errors }
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      fullname: '',
      email: '',
      phone: '',
      website: '',
      businessSize: ''
    }
  })

  const handleChange = (elem: SingleValue<IOption>) => {
    setValue('businessSize', elem?.value || '')
    setError('businessSize', {})

    setOption(elem)
  }

  const handleRequest = (data: any) => {
    setIsDone(true)
  }

  if (isDone) {
    return (
      <Right>
        <ArrowTop
          className='scroll-top-arrow'
          onClick={() => {
            nativeScroll(ScrollSectionsEnum.business)
          }}
        />
        <ThankContent>
          <Title>Thank you!</Title>
          <SubTitle>Our sales team will be in touch with you soon. </SubTitle>
        </ThankContent>
      </Right>
    )
  }

  const { ref, ...phoneConfigs } = {
    ...register('phone', {
      required: {
        value: true,
        message: 'Please provide your phone number.'
      },
      pattern: {
        value: PHONE_REGEX,
        message: 'Please enter a valid phone number (e.g., (123) 123-1234 )'
      }
    })
  }

  return (
    <Right>
      <ArrowTop
        className='scroll-top-arrow'
        onClick={() => {
          nativeScroll(ScrollSectionsEnum.business)
        }}
      />
      <Content>
        <div>
          <Title>Request a Demo</Title>
          <SubTitle>Fill in the short form to sign up for a preview. </SubTitle>
          <Form onSubmit={handleSubmit(handleRequest)}>
            <Inputs>
              <Input
                error={errors?.fullname?.message || ''}
                placeholder='First & last name*'
                {...register('fullname', {
                  required: {
                    value: true,
                    message: 'Please provide your first and last name.'
                  },
                  pattern: {
                    value: /^[A-Za-z]+ [A-Za-z]+$/,
                    message: 'Please enter a valid full name'
                  }
                }
                )}
              />
              <Input
                error={errors?.email?.message || ''}
                placeholder='your email*'
                {...register('email', {
                  required: {
                    value: true,
                    message: 'Please provide your email address.'
                  },
                  pattern: {
                    value: EMAIL_REGEX,
                    message: 'Please enter a valid email address.'
                  }
                })}
              />
              <PhoneInput
                placeholder='Your phone number*'
                error={errors?.phone?.message || ''}
                watch={watch}
                trigger={trigger}
                setValue={setValue}
                {...phoneConfigs}
              />
              <Input
                error={errors?.website?.message || ''}
                placeholder='Business website or social link*'
                {...register('website', {
                  required: {
                    value: true,
                    message: 'Please enter a valid website URL.'
                  },
                  pattern: {
                    value: WEBSITE,
                    message: 'This website URL is not valid. Please enter a valid URL (e.g., http://www.example.com)'
                  }
                })}
              />
            </Inputs>
            <SelectWrapper>
              <Select
                {...register('businessSize', { required: 'The Business size is required' })}
                value={option}
                options={options}
                onChange={handleChange}
                className='react-select-container'
                defaultValue={{ value: 'Business size', label: 'Business size' }}
                menuPlacement={isMobile ? 'top' : 'bottom'}
              />
              <Small>{errors?.businessSize?.message || ''}</Small>
            </SelectWrapper>
            <Button>Submit</Button>
          </Form>
        </div>
      </Content>
    </Right>
  )
}

export default RightSide
