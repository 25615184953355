import styled from 'styled-components'

import { device } from '@theme'

export const StyledInput = styled.input`
  max-width: 464px;
  width: 100%;
  height: 62px;
  padding: 15px 20px;
  border-radius: 11.955px;
  border: 1px solid #d5dff0;
  background: unset;
  
  ::placeholder {
    color: #959BA9;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
  }

  @media ${device.mobile} {
    height: 48px;
    max-width: 100%;

    ::placeholder {
      font-size: 13px;
      font-weight: 400;
    }
  }
`

export const Small = styled.small`
  display: block;
  color: #970505;
  text-align: left;
  font-size: 13px;

  @media ${device.height800} {
    margin-top: 0px;
    font-size: 15px;
  }

  @media ${device.height700} {
    margin-top: 0px;
    font-size: 13px;
  }

  @media ${device.mobile} {
    margin-top: 0px;
    font-size: 10px;
  }
`
