import { useContext } from 'react'
import { nativeScroll } from '@utils/moveTo'
import { ScrollSectionsEnum, SectionOfStickyEnum } from '@helpers/enums'
import { MainContext } from '@context/main'
import { SwitcherContainer, Title } from './styled'

const TopSwitcherSticky = ({ contentType }: { contentType: SectionOfStickyEnum }) => {
  const { setBusinessState, setShopperState } = useContext(MainContext)
  return (
    <SwitcherContainer>
      <Title
        active={contentType === SectionOfStickyEnum.shoppers ? 'active' : ''}
        onClick={() => {
          nativeScroll(ScrollSectionsEnum.shoppers)
          setShopperState({ subSection: 0 })
        }}
      >
        For Shoppers
      </Title>
      <span />
      <Title
        active={contentType === SectionOfStickyEnum.business ? 'active' : ''}
        onClick={() => {
          nativeScroll(ScrollSectionsEnum.business)
          setBusinessState({ subSection: 0 })
        }}
      >
        For Business
      </Title>
    </SwitcherContainer>
  )
}

export default TopSwitcherSticky
