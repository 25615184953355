import styled from 'styled-components'

export const StyledPagination = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 40px;
  padding: 0 15px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  .points {
    display: flex;
    gap: 15px;
    align-items: center;
    justify-content: space-between;

    > .wrapper {
      padding: 5px;
      
      .point {
        cursor: pointer;
        border-radius: 50%;
        width: 6px;
        height: 6px;
        background: #000;
        opacity: 0.2;

        &.active {
          opacity: 1;
          background: #232BDD;
        }
      }
     
    }
  }
`

export const SvgWrapper = styled.div`
  padding: 7px;
`
