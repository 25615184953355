import { useContext, useEffect, useRef, useState } from 'react'

import { HamburgerTypeEnum, StickyScrollableContainersEnum } from '@helpers/enums'
import { MainContext } from '@context/main'

export const useScroll = () => {
  const [opacityHome, setOpacityHome] = useState(1)
  const { setMenuType } = useContext(MainContext)
  const mainContainerRef = useRef<HTMLDivElement>(null)
  const homeContainerRef = useRef<HTMLDivElement>(null)
  const [homeHeight, setHomeHeight] = useState<number>(0)

  const handleScroll = () => {
    // @ts-ignore
    const scrollProgress = mainContainerRef.current.scrollTop / ((mainContainerRef.current.scrollHeight + mainContainerRef.current.clientHeight) - mainContainerRef.current.scrollHeight)
    // @ts-ignore
    const height = homeContainerRef.current.scrollHeight
    // @ts-ignore
    const { scrollTop } = mainContainerRef.current

    if (scrollTop <= height) {
      setOpacityHome(1 - scrollProgress)
    } else if (scrollTop >= height && scrollTop <= (height * 2)) {
      if (scrollTop === window.innerHeight * 2) {
        setOpacityHome(0)
      }
    } else if (scrollTop >= (height * 2) && scrollTop <= (height * 3)) {
      setOpacityHome(scrollProgress - 2)
    }

    const mainScrollTop = mainContainerRef.current?.scrollTop || 0
    if (mainScrollTop < homeHeight) {
      setMenuType(HamburgerTypeEnum.primary)
    } else if (mainScrollTop >= homeHeight && mainScrollTop < homeHeight * 2) {
      setMenuType(HamburgerTypeEnum.secondary)
    } else if (mainScrollTop >= homeHeight * 2 && mainScrollTop < homeHeight * 3) {
      setMenuType(HamburgerTypeEnum.secondary)
    } else if (mainScrollTop >= homeHeight * 2) {
      setMenuType(HamburgerTypeEnum.primary)
    }
  }

  useEffect(() => {
    const mainContainer = mainContainerRef.current

    if (homeContainerRef.current) {
      setHomeHeight(homeContainerRef.current.clientHeight)
    }

    const scrollListener = () => requestAnimationFrame(() => handleScroll())

    mainContainer?.addEventListener('scroll', scrollListener)

    return () => {
      mainContainer?.removeEventListener('scroll', scrollListener)
    }
  }, [handleScroll])

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      handleScroll()
    }, 2000)

    return () => {
      clearTimeout(timeoutId)
    }
  }, [handleScroll])

  const initialPosition = () => {
    if (document.visibilityState === 'hidden') {
      // @ts-ignore
      mainContainerRef.current.scrollTop = 0
      return
    }

    // @ts-ignore
    mainContainerRef.current.scrollTop = 0
  }

  useEffect(() => {
    function isSafari() {
      return /^((?!chrome|android).)*safari/i.test(navigator.userAgent)
    }

    if (isSafari()) {
      window.addEventListener('visibilitychange', initialPosition)
      window.addEventListener('unload', initialPosition)
    }

    return () => {
      window.removeEventListener('visibilitychange', initialPosition)
      window.removeEventListener('unload', initialPosition)
    }
  }, [])

  return {
    opacityHome,
    mainContainerRef,
    homeContainerRef
  }
}
