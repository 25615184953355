import styled from 'styled-components'

import { device } from '@theme'

export const Container = styled.div`
  max-width: 446px;
  margin: 95px 64px;

  h2 {
    color: #151515;
    text-align: left;
    font-family: 'Manrope';
    font-size: 48px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
  }

  p {
    margin-top: 14px;
  }

  @media ${device.mobile} {
    margin: 0px 64px;
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    h2 {
      font-size: 24px;
      line-height: 129.5%;
    }

    p {
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 126%;
    }
  }
`

export const Form = styled.form`
  display: flex;
  flex-direction: column;

  input {
    margin: 32px 0px 0px 0px;
  }

  button {
    margin-top: 32px;
  }

  @media ${device.mobile} {
    text-align: left;
    max-width: 270px;
    width: 100%;

    button {
      width: 197px;
      margin: 0 auto;
      margin-top: 32px;
    }
  }
`
