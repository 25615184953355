import wallet from '@assets/images/for-shoppers/wallet.webp'
import notifications from '@assets/images/for-shoppers/notifications.webp'
import locationBasedOffers from '@assets/images/for-shoppers/location-based-offers.webp'
import favoritesList from '@assets/images/for-shoppers/favorites-list.webp'
import discoverPromotions from '@assets/images/for-shoppers/discover-promotions.webp'

import requestADemo from '@assets/images/for-business/request-a-demo.webp'
import realTimeTracking from '@assets/images/for-business/real-time-tracking.webp'
import enhancedVisibility from '@assets/images/for-business/enhanced-visibility.webp'
import createPromotions from '@assets/images/for-business/create-promotions.webp'
import businessDashboard from '@assets/images/for-business/business-dashboard.webp'

export const forShopperStaticData = [
  {
    id: 0,
    name: 'Discover Promotions',
    src: discoverPromotions,
    description:
      'Unearth unique, time-bound promotions from businesses near you. Take the power of saving more bucks on your everyday purchases.'
  },
  {
    id: 1,
    name: 'Wallet',
    src: wallet,
    description:
      'Organize your selections in your personal digital wallet, never missing out on the best offers.'
  },
  {
    id: 2,
    name: 'Favorites List',
    src: favoritesList,
    description:
      'Follow your favorite businesses and categories to get personalized notifications. Be the first to know about new deals.'
  },
  {
    id: 3,
    name: 'Location-Based Offers',
    src: locationBasedOffers,
    description:
      'Explore the interactive map to see what businesses around you are offering special promotions. Step out, save money and support local businesses.'
  },
  {
    id: 4,
    name: 'Notification',
    src: notifications,
    description:
      "Stay updated with notifications about promotions from businesses you love and nearby offers you don't want to miss."
  }
]

export const forBusinessStaticData = [
  {
    id: 0,
    name: 'Business Dashboard',
    src: businessDashboard,
    description:
      "Manage your promotions effortlessly with our easy-to-use dashboard. Track your promotion's performance in real-time."
  },
  {
    id: 1,
    name: 'Create Promotions',
    src: createPromotions,
    description:
      'Increase your sales, customer loyalty, and overall business growth with Saving Bucks. A simple solution for your complex problems.'
  },
  {
    id: 2,
    name: 'Real-Time Tracking',
    src: realTimeTracking,
    description:
      'Get valuable insights with real-time tracking of your promotions. Understand your customers better and adapt your business strategies.'
  },
  {
    id: 3,
    name: 'Enhanced Visibility',
    src: enhancedVisibility,
    description:
      'Boost your business visibility and reach. Our platform helps you connect with the right customers at the right time.'
  },
  {
    id: 4,
    name: 'Business Growth',
    src: requestADemo,
    description: null
  }
]
