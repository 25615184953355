import { RefObject, useContext, useEffect, useRef, forwardRef, ForwardRefRenderFunction } from 'react'

import { ScrollSectionsEnum } from '@helpers/enums'
import { MainContext } from '@context/main'
import backgroundUniversal from '@assets/backgrounds/universal.webp'

import { ContainerAndContentMiddle, Content, HomeContainer, PageAndImageWrapper } from './styled'
import RightSide from './components/RightSide'
import LeftSide from './components/LeftSide'

const Home = ({ opacity }: any, ref: any) => {
  const { setContentWidth } = useContext(MainContext)
  const contentRef = useRef<null | HTMLDivElement>(null)

  useEffect(() => {
    const timeOut = setTimeout(() => {
      if (contentRef.current?.offsetWidth) {
        setContentWidth(contentRef.current?.offsetWidth)
      }
    }, 300)

    return () => clearTimeout(timeOut)
  }, [])

  return (
    <PageAndImageWrapper
      ref={ref}
      id={ScrollSectionsEnum.home}
    >
      <HomeContainer ref={contentRef}>
        <ContainerAndContentMiddle>
          <Content>
            <LeftSide />
            <RightSide />
          </Content>
        </ContainerAndContentMiddle>
      </HomeContainer>
    </PageAndImageWrapper>
  )
}

export default forwardRef(Home)
