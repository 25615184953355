import { useContext } from 'react'

import { nativeScroll } from '@utils/moveTo'
import useIsMobile from '@hooks/useIsMobile'
import {
  ScrollSectionsEnum,
  SectionOfStickyEnum
} from '@helpers/enums'
import { MainContext } from '@context/main'
import Button, { ButtonTypeEnum } from '@Components/Button'
import { ReactComponent as LetsStart } from '@assets/svg/letsStart.svg'
import { ReactComponent as ArrowBottom } from '@assets/svg/arrow-bottom.svg'
import logo from '@assets/images/logo.png'

import {
  Left,
  Title,
  MixWrapper,
  LogoWrapper,
  ButtonContainer,
  TitlesContainer,
  ScrollToExplore
} from './styled'

const LeftSide = () => {
  const { isMobile } = useIsMobile()
  const { setShopperState, setBusinessState } = useContext(MainContext)

  return (
    <Left>
      <div>
        <LogoWrapper>
          <LetsStart />
          <img src={logo} alt='logo' />
        </LogoWrapper>
      </div>

      <TitlesContainer>
        <Title>Discover amazing deals.</Title>
        <Title>Grow your business.</Title>
        <Title>All in one platform.</Title>
      </TitlesContainer>

      <MixWrapper>
        <p>Coming Soon:</p>
        <ButtonContainer>
          <Button
            buttonType={isMobile ? ButtonTypeEnum.thirdly : ButtonTypeEnum.secondary}
            onClick={() => {
              setShopperState({ subSection: 0 })
              nativeScroll(ScrollSectionsEnum.shoppers)
            }}
          >
            For Shoppers
          </Button>
          <Button
            buttonType={isMobile ? ButtonTypeEnum.secondary : ButtonTypeEnum.primary}
            onClick={() => {
              setBusinessState({ subSection: 0 })
              nativeScroll(ScrollSectionsEnum.business)
            }}
          >
            For Businesses
          </Button>
        </ButtonContainer>
      </MixWrapper>
      <ScrollToExplore
        onClick={() => {
          setShopperState({ subSection: 0 })
          nativeScroll(ScrollSectionsEnum.shoppers)
        }}
      >
        Scroll to Explore
        <ArrowBottom />
      </ScrollToExplore>
    </Left>
  )
}

export default LeftSide
