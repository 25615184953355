import { memo, useContext } from 'react'

import { SectionOfStickyEnum, StickyScrollableContainersEnum } from '@helpers/enums'
import { MainContext } from '@context/main'
import { forBusinessStaticData, forShopperStaticData } from '@Components/StickyDesktop/data'
import {
  Item,
  ShoppersItem,
  BusinessItems,
  ItemSpecial,
  RightItemWrappers,
  RightSectionLayerWrapper,
  RightSectionOverflowWrapper,
  RightScrollableContentContainer,
  RightSection as StyledRightSection
} from './styled'

const RightSection = ({ contentType }: { contentType: SectionOfStickyEnum }) => {
  const { shopperState, businessState } = useContext(MainContext)

  return (
    <StyledRightSection>
      <RightSectionLayerWrapper>
        <RightSectionOverflowWrapper id='border-gradient-container'>
          <RightScrollableContentContainer>
            <RightItemWrappers>
              {
                contentType === SectionOfStickyEnum.shoppers ? (
                  <ShoppersItem>
                    {forShopperStaticData.map((item) => {
                      const fixedId = shopperState.subSection as number

                      return (
                        <Item key={item.name} className={fixedId === item.id ? 'active' : ''}>
                          <img src={item.src} alt={item.name} />
                          <p>{item.description}</p>
                        </Item>
                      )
                    })}
                  </ShoppersItem>
                ) : (
                  <BusinessItems>
                    {forBusinessStaticData.map((item) => {
                      // @ts-ignore
                      const fixedId = businessState.subSection as number

                      if (item.name === 'Business Growth') {
                        return (
                          <ItemSpecial key={item.name} className={fixedId === item.id ? 'active' : ''}>
                            <p><span>Increase</span> your sales, customer loyalty, and overall business growth <span>with Saving Bucks.</span></p>
                            <img src={item.src} alt={item.name} />
                          </ItemSpecial>
                        )
                      }

                      return (
                        <Item key={item.name} className={fixedId === item.id ? 'active' : ''}>
                          <img src={item.src} alt={item.name} />
                          <p>{item.description}</p>
                        </Item>
                      )
                    })}
                  </BusinessItems>
                )
              }
            </RightItemWrappers>
          </RightScrollableContentContainer>
        </RightSectionOverflowWrapper>
      </RightSectionLayerWrapper>
    </StyledRightSection>
  )
}

export default memo(RightSection)
