import styled from 'styled-components'

import { device } from '@theme'

export const Right = styled.div`
  width: 50%;
  display: flex;
  justify-content: end;

  .scroll-top-arrow {
    display: none;
  }

  @media ${device.mobile} {
    width: 100%;
    justify-content: center;

    .scroll-top-arrow {
      display: block;
      position: absolute;
      top: 20px;
      z-index: 111;
    }
  }
`
export const Content = styled.div`
  width: 100%;
  height: 100%;
  max-width: 629px;
  max-height: 772px;
  border-radius: 15px;
  background: #F5F5F5;
  padding: 30px 38px;
 
  > div {
    text-align: center;
    margin-top: 165px;
  }

  @media ${device.monitor} {
    font-size: 40px;
    padding: 20px 38px;

    > div {
      margin-top: 100px;
    }
  }

  @media ${device.height800} {
    > div {
      margin-top: 80px;
    }
  }

  @media ${device.laptop} {
    font-size: 35px;
    max-height: 100%;
    > div {
      margin-top: 70px;
    }
  }

  @media ${device.height700} {
    > div {
      margin-top: 30px;
    }
  }

  @media ${device.tablet} {
    font-size: 30px;
    > div {
      margin-top: 30px;
    }
  }

  @media ${device.height600} {
    > div {
      margin-top: 20px;
    }
  }

  @media ${device.mobile} {
    height: max-content;

    > div {
      text-align: center;
      margin-top: 10px;
    }

    padding: 25px 28px 15px 28px;
  }
`

export const ThankContent = styled.div`
  width: 100%;
  height: 100%;
  max-width: 629px;
  max-height: 772px;
  border-radius: 15px;
  background: #F5F5F5;
  padding: 0 38px;
  text-align: center;

  display: flex;
  flex-direction: column;
  justify-content: center;

  @media ${device.monitor} {
    font-size: 40px;
    padding: 20px 38px;

    > div {
      margin-top: 100px;
    }
  }

  @media ${device.laptop} {
    font-size: 35px;
    height: 100%;
    max-height: 607px;
    
    > div {
      margin-top: 70px;
    }
  }

  @media ${device.tablet} {
    font-size: 30px;
    > div {
      margin-top: 30px;
    }
  }

  @media ${device.mobile} {
    height: ${window.innerHeight - 80}px;
    max-height: 621px;
    padding: 25px 28px 15px 28px;
  }

`

export const Title = styled.h2`
  text-align: center;
  font-family: 'Manrope';
  font-size: 48px;
  font-style: normal;
  font-weight: 500;
  line-height: 66px;
  letter-spacing: 0em;

  @media ${device.monitor} {
    font-size: 40px;
  }

  @media ${device.laptop} {
    font-size: 35px;
  }

  @media ${device.tablet} {
    font-size: 30px;
  }

  @media ${device.mobile} {
    font-size: 24px;
    line-height: 129.5%;
  }
`

export const SubTitle = styled.p`
  font-family: 'Manrope';
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 126%;
  margin-top: 17px;

  @media ${device.tablet} {
    font-size: 13px;
  }

  @media ${device.mobile} {
    font-size: 16px;
    margin-top: 26px;
    font-weight: 400;
    text-align: center;
  }
`

export const Form = styled.form`
  margin-top: 44px;

  > button {
    display: block;
    margin: 44px auto 0 auto;
    max-width: 197px;
  }

  @media ${device.mobile} {
    margin-top: 19px;
    width: 100%;

    > button {
      margin: 26px auto 2vh auto;
      display: block;
    }
  }
`

export const SelectWrapper = styled.div`
  width: max-content;
  margin: 17px auto;

  .react-select-container {
    display: flex;
    width: 270px;
    text-align: left;
    align-items: center;
    gap: 118px;
    border-radius: 11.955px;
    border: 1px solid #D5DFF0; 
    

    svg {
      fill: #151515;
    }

    > div {
      width: 100%;
      border: none;
      font-size: 15px;
      border-radius: 11.955px;
      background: unset;
      border-radius: 15px;
      padding: 13px 10px;

      > div {
        &:last-child {
          border: none;

          > span {
            width: 0px;
          }

          > div {
            padding: 8px 9px 8px 8px;
          }
        }
      }
    }
  }

  @media ${device.mobile} {
    width: 100%;
    text-align: left;
    margin: 11px auto 17px auto;

    .react-select-container {
      width: 100%;

      > div {
        padding: 6px 10px;
      }
    }
  }
`

export const Inputs = styled.div`
  display: grid;
  gap: 18px 13px;
  grid-template-columns: minmax(100px, 270px) minmax(100px, 270px);

  > div {
    display: flex;
    flex-direction: column;
  }

  > input {
    height: 62px;
    font-family: 'Manrope';
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: capitalize;
  }


  @media ${device.mobile} {
    grid-template-columns: 1fr;

    > input {
      height: 48px;
      max-width: unset !important;
    }

    gap: 10px;
    grid-template-columns: 1fr;
  }
`
