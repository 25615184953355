import { createGlobalStyle } from 'styled-components'
import { device } from '@theme'

import ManropeWOFF2 from '../fonts/Manrope/Manrope.woff2'
import ManropeWOFF from '../fonts/Manrope/Manrope.woff'
import ManropeTTF from '../fonts/Manrope/Manrope.ttf'

const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family:'Manrope';
    font-style: normal;
    font-display: auto;
    src:local('Manrope'),
    url(${ManropeWOFF}) format("woff"),
    url(${ManropeTTF}) format("truetype"),
    url(${ManropeWOFF2}) format("woff2");
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;

    ::-webkit-scrollbar {
      display: none;
    }
  }

  body {
    margin: 0;
    padding: 0;
    width: inherit;
    min-height: 100%;
    height: max-content;
    display: flex;
    flex-direction: column;
    font-family: 'Manrope';
    overflow-x: hidden;

    input {
      font-family: 'Manrope';
      outline: none !important;
      -webkit-appearance: none;
      padding: 0;
      background-color: none;
      border: none;
      border-radius: 0;
      outline: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;

      :focus {
        outline: none;
      }

      ::placeholder {
        font-size: 13px;
        font-weight: 700;
      }
    }

    button {
      border: none;
    }

    a {
      font-family: 'Manrope';
      display: block;
      text-decoration: none;
      cursor: pointer;

      &:hover {
        text-decoration: none;
      }
    }
  }
`
export default GlobalStyle
