import styled, { css } from 'styled-components'

import { device } from '@theme'

import { ButtonTypeEnum } from '.'

const commonStyles = css`
  font-family: 'Manrope';
  font-size: 16px;
  font-weight: 600;
  line-height: 126%;
  font-style: normal;
  width: 197px;
  height: 52px;
  color: #151515;
  cursor: pointer;

  @media ${device.mobile} {
    width: 245px;
    height: 38px;
    padding: 0.532px 0px;
  }
`

const secondaryStyles = css`
  ${commonStyles};
  padding: 0.532px 0px;
  border-radius: 50px;
  background: ${({ theme }) => theme.colors.white};
`

const thirdlyStyles = css`
  ${commonStyles};
  width: 192px;
  height: 40px;
  border-radius: 38.026px;
  background: unset;
  border: 1px solid ${({ theme }) => theme.colors.buttonBorder};
  color: #151515;
`

const fourthStyles = css`
  ${commonStyles};
  width: 197px;
  height: 52px;
  border-radius: 50px;
  padding: 0.532px 0px;
  border: 1px solid #ddd;
  background: none;
`

const defaultStyles = css`
  ${commonStyles};
  padding: 0.532px 0px;
  border-radius: 50px;
  color: ${({ theme }) => theme.colors.white};
  background: ${({ theme }) => theme.colors.blue};
`

export const StyledButton = styled.button<{ buttonType: ButtonTypeEnum }>`
  ${({ buttonType }) => {
    switch (buttonType) {
      case 'secondary':
        return secondaryStyles
      case 'thirdly':
        return thirdlyStyles
      case 'fourth':
        return fourthStyles
      default:
        return defaultStyles
    }
  }}
`
