import device from '@assets/images/device.png'

import { Right } from './styled'

const RightSide = () => {
  return (
    <Right>
      <img src={device} alt='Device, Iphone' />
    </Right>
  )
}

export default RightSide
