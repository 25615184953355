import { useContext } from 'react'
import { useForm } from 'react-hook-form'

import { ContentTypeEnum, SizeTypeEnum } from '@helpers/enums'
import { MainContext } from '@context/main'
import Input from '@Components/Input'
import Button from '@Components/Button'

import { Container, Form } from './styled'

const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i

const JoinWaitlist = () => {
  const { setPopUp } = useContext(MainContext)

  const handleRequest = (event: any) => {
    setPopUp((prev) => ({ ...prev, size: SizeTypeEnum.big, contentType: ContentTypeEnum.thankYou }))
  }

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors }
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      email: ''
    }
  })

  return (
    <Container>
      <h2>Join the Waitlist</h2>
      <p>Sign up for our waitlist and be the first to know when Saving Bucks is going live! </p>
      <Form onSubmit={handleSubmit(handleRequest)}>
        <Input
          placeholder='Your E-Mail Address'
          error={errors?.email?.message || ''}
          {...register('email', {
            required: {
              value: true,
              message: 'Please provide your email address.'
            },
            pattern: {
              value: EMAIL_REGEX,
              message: 'Please enter a valid email address.'
            }
          })}
        />
        <Button>Submit</Button>
      </Form>
    </Container>
  )
}

export default JoinWaitlist
