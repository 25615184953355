import styled from 'styled-components'

import { device } from '@theme'

export const Left = styled.div`
  width: 50%;
  display: flex;
  flex-direction: column;

  @media ${device.mobile} {
    display: none;
  }
`

export const LogoWrapper = styled.div`
  margin-top: 28px;
  
  > img {
    width: 113px;
    height: 117px;
  }
`

export const Title = styled.h2`
  color: #FFF;
  font-family: 'Manrope';
  font-size: 48px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  opacity: 0.6;
  margin-top: 167px;
  white-space: pre-line;

  @media ${device.monitor} {
    font-size: 40px;
    margin-top: 120px;
  }

  @media ${device.laptop} {
    font-size: 35px;
    margin-top: 100px;
  }

  @media ${device.tablet} {
    font-size: 30px;
    margin-top: 60px;
  }
`
