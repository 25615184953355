import { useContext, useEffect, useState } from 'react'

import { utilClosingPopUp } from '@utils/updatePopUp'
import { HamburgerTypeEnum, SizeTypeEnum, TriggerEnum } from '@helpers/enums'
import { MainContext } from '@context/main'
import { ReactComponent as Hamburger } from '@assets/svg/hamburger.svg'
import { ReactComponent as HamburgerGray } from '@assets/svg/hamburger-gray.svg'
import { ReactComponent as Close } from '@assets/svg/close.svg'

import { Header as StyledHeader } from './styled'

const Header = ({ isMobile, size }: { isMobile: boolean; size: SizeTypeEnum }) => {
  const { contentWidth, popUp, setPopUp } = useContext(MainContext)
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const { menuType } = useContext(MainContext)

  const handleTrigger = () => {
    utilClosingPopUp({
      closingSidebarWithDelayForChangeContent: () => {
        setPopUp((prev) => {
          if (prev.status === TriggerEnum.closed) {
            return { ...prev, status: TriggerEnum.opened }
          }

          return { ...prev, status: TriggerEnum.closed }
        })
      },
      setPopUp
    })
  }

  const fixMenuIconPosition = () => {
    setWindowWidth(window.innerWidth)
  }

  useEffect(() => {
    window.addEventListener('resize', fixMenuIconPosition)

    return () => {
      window.removeEventListener('resize', fixMenuIconPosition)
    }
  }, [])

  const renderContent = () => {
    return menuType === HamburgerTypeEnum.primary ?
      (
        <>
          <Close className='close-icon' />
          <Hamburger className='open-icon' />
        </>
      ) :
      (
        <>
          <Close className='close-icon' />
          <HamburgerGray className='open-icon' />
        </>
      )
  }

  if (contentWidth === 0) return null

  return (
    <StyledHeader
      size={size}
      isMobile={isMobile}
      status={popUp.status}
      windowWidth={windowWidth}
      contentWidth={contentWidth}
      onClick={handleTrigger}
    >
      {renderContent()}
      <div />
    </StyledHeader>
  )
}

export default Header
