import styled from 'styled-components'

import { device } from '@theme'

export const PageAndImageWrapper = styled.div`
  position: relative;
  height: 100%;

  @media ${device.mobile} {
    height: 100%;
  }
`

export const ContainerAndContentMiddle = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media ${device.mobile} {
    height: 100%;
  }
`

export const RequestDemoContainer = styled.div`
  height: 100%;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;

  > img {
    position: absolute;
  }

  @media ${device.mobile} {
    height: 100%;
  }
`

export const Content = styled.div`
  display: flex;
  justify-content: space-between;
  height: 100%;
  margin-top: 50px;
  margin-bottom: 55px;
  width: 100%;
  padding: 0 50px 0 66px;

  @media ${device.mobile} {
    height: 100%;
    margin-top: 86px;
    padding: 20px 24px 20px 24px;
    justify-content: center;
    margin-bottom: 60px;
  }
`
