import React from 'react'

import { ThemeProvider } from 'styled-components'
import { Fonts } from '@assets/GlobalStyle/fonts'

export const COLORS = {
  white: '#fff',
  blue: '#232BDD',
  textDark: '#262225',
  textGray: '#959BA9',
  buttonBorder: '#ddd',
  inputBorder: '#D5DFF0',
  black: '#000000'
}

export const breakpoints = {
  xs: 0,
  sm: 600,
  md: 920,
  l: 1025,
  lg: 1200,
  xl: 1360,
  el: 1440
}

export const device = {
  mobile: `(max-width: ${breakpoints.md}px)`,
  tablet: `(max-width: ${breakpoints.l}px)`,
  laptop: `(max-width: ${breakpoints.lg}px)`,
  monitor: `(max-width: ${breakpoints.xl}px)`,
  desktop: `(max-width: ${breakpoints.el}px)`,
  height800: '(max-height: 850px)',
  height700: '(max-height: 750px)',
  height600: '(max-height: 600px)'
}

const theme = {
  colors: COLORS,
  fonts: {
    manrope: Fonts.Manrope
  },
  fontSizes: {},
  breakpoints
}

const Theme = ({ children }: { children: React.ReactNode }) => (
  <ThemeProvider theme={theme}>{children}</ThemeProvider>
)

export default Theme
