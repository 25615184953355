import styled from 'styled-components'

import { device } from '@theme'

export const Header = styled.div`
  height: 45px;
  width: 100%;
  max-width: 90vw;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 50px 1fr minmax(140px, 1fr);
  align-items: center;

  @media ${device.mobile} {
    grid-template-columns: 25px 1fr minmax(140px, 1fr);
    grid-template-rows: 40px;
  }
`

export const SectionName = styled.div`
  p {
    color: #959BA9;
    font-family: 'Manrope';
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    margin-left: 5px;
  }
`

export const SomeAction = styled.div`
  justify-self: flex-end;

  > div {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 1px;
  }
`

export const Border = styled.div`
  background: #232BDD;
  width: 100%;
  height: 2px;
`
export const SvgPWrapper = styled.div`
  position: relative;
  z-index: 10;
  display: flex;
  justify-content: space-between;
  gap: 10px;
  align-items: center;
  white-space: nowrap;

  p {
    color: #232BDD;
    font-family: 'Manrope';
    font-size: 14px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    white-space: nowrap; 
  }

  svg {
    cursor: pointer  
  }
`
