import styled from 'styled-components'
import { device } from '@theme'

export const PageContainer = styled.div`
  position: relative;
  margin: 0 auto;
  width: 100%;
  overflow: scroll;
  height: ${window.innerHeight}px;
  scroll-snap-type: y mandatory;
  will-change: scroll-position;

  .gradients-wrapper {
    top: 50%;
    right: 50%;
    height: 400vh;
    width: 100%;
    z-index: -1;
    -ms-transform: translate(50%, -50%);
    transform: translate(50%, -50%);
    position: absolute;
    max-width: 1440px;
    margin: 0 auto;

    @media (max-width: 1440px) {
      overflow: hidden;
    }

    @media ${device.mobile} {
      transform: unset;
      top: 0;
      right: 0;
    }


    .desktop-gradients-first {
      position: absolute;
      top: 62%;
      right: -180px;
      width: 416.073px;
      height: 549.144px;
      transform: rotate(-99.78deg);
      flex-shrink: 0;
      border-radius: 549.144px;
      background: radial-gradient(41.59% 44.00% at 38.52% 57.75%, #B6C2DB 0%, rgba(182, 194, 219, 0.00) 100%);

      @media ${device.mobile} {
        right: -78px;
        top: 29%;
        width: 200px;
        height: 200px;
      }
    }

     .desktop-gradients-second {
      position: absolute;
      top: 280vh;
      right: -162px;
      width: 330px;
      height: 600px;
      flex-shrink: 0;
      border-radius: 1171.741px;
      background: radial-gradient(50% 50%, rgb(243, 154, 154) 31.37%, rgba(243, 154, 154, 0) 100% );
      filter: blur(0px);
  
      @media ${device.mobile} {
        top: 33%;
        right: -70px;
        width: 200px;
        height: 400px;
      }
    }

    .desktop-gradients-third {
      overflow: hidden;
      position: absolute;
      top: 333vh;
      right: -180px;
      width: 500px;
      height: 500px;
      transform: rotate(-99.78deg);
      flex-shrink: 0;
      border-radius: 1038.78px;
      background: radial-gradient(50% 50%, #232BDD 31.37%, rgba(243, 154, 154, 0) 100% );
      filter: blur(0);

      @media ${device.mobile} {
        right: -180px;
        top: 56%;
        width: 300px;
        height: 300px;
      }
    }
  }
`

export const MainContentsWrapper = styled.div`
  width: 100%; 
  height: max-content;
  height: ${window.innerHeight}px;
  position: relative;
  scroll-snap-align: start;
  
  .top-blue-gradient-desktop {
    position: absolute;
    z-index: 1;
    bottom: -220px;
    left: 50%;
    right: 50%;

    width: 597.591px;
    height: 471.765px;
    transform: translate(-60%, -60%) rotate(-13.881deg);
    flex-shrink: 0;
    border-radius: 597.591px;
    background: radial-gradient(50% 50.00% at 50% 50.00%, #232BDD 0%, rgba(35, 43, 221, 0.00) 100%);
    filter: blur(0px);

    &.active {
      transform: translate(-60%, -20%) rotate(-13.881deg);
    }


    @media ${device.mobile} {
      display: none;
    }

    transition: 1s all ease;
  }

  .top-blue-gradient-mobile-wrapper {
    display: none;
    position: absolute;
    bottom: -60px;
    width: 100%;
    height: 200px;
    z-index: 1;
    overflow: hidden;

    .gradient {
      width: 297px;
      height: 171px;
      transform: translate(80%, 10%) rotate(-13.881deg);
      background: radial-gradient(50% 50.00% at 50% 50.00%, #232BDD 0%, rgba(35, 43, 221, 0.00) 100%);
      filter: blur(0px);
    }


    @media ${device.mobile} {
      display: none;


      &.active {
        display: block;
      }
    }
  }
`
