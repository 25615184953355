import { forwardRef, ChangeEvent, useEffect } from 'react'
import {
  UseFormWatch, UseFormSetValue, UseFormTrigger
} from 'react-hook-form'
import { IFormStat } from '@pages/RequestDemo/components/RightSide'

import { Container, Small, StyledPhoneNumber } from './styled'

interface IInput {
  name: 'phone';
  error: string;
  placeholder: string;
  setValue: UseFormSetValue<IFormStat>;
  watch: UseFormWatch<IFormStat>;
  trigger: UseFormTrigger<IFormStat>;
}

const PhoneInput = ({ name, placeholder, error, setValue, watch, trigger }: IInput) => {
  const value = watch(name)

  return (
    <Container>
      <StyledPhoneNumber
        country='us'
        onlyCountries={['us']}
        disableCountryCode
        placeholder={placeholder}
        onChange={(val: any) => {
          if (val.length === 10) {
            setValue(name, val)
            trigger(name)
          } else if (val.length < 10) {
            setValue(name, val)
            trigger(name)
          }
        }}
      />
      {error && <Small>{error}</Small>}
    </Container>
  )
}

export default PhoneInput
