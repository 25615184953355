import { ScrollSectionsEnum, StickyScrollableContainersEnum } from '@helpers/enums'

export const nativeScroll = (to: ScrollSectionsEnum) => {
  const windowHeight = window.innerHeight
  const main = document.getElementById(StickyScrollableContainersEnum.main)

  if (main === null) {
    return
  }

  if (to === ScrollSectionsEnum.home) {
    main.scroll({
      top: 0,
      behavior: 'smooth'
    })
    return
  }

  if (to === ScrollSectionsEnum.shoppers) {
    main.scroll({
      top: windowHeight,
      behavior: 'smooth'
    })
    return
  }

  if (to === ScrollSectionsEnum.business) {
    main.scroll({
      top: windowHeight * 2,
      behavior: 'smooth'
    })

    // setTimeout(() => {
    //   main.scroll({
    //     top: windowHeight * 2,
    //     behavior: 'smooth'
    //   })
    // }, 100)

    return
  }

  if (to === ScrollSectionsEnum.requestDemo) {
    main.scroll({
      top: windowHeight * 3,
      behavior: 'smooth'
    })
  }
}
