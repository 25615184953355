import logo from '@assets/images/logo.png'

import { Left, LogoWrapper, Title } from './styled'

const LeftSide = () => {
  return (
    <Left>
      <LogoWrapper>
        <img src={logo} alt='logo' />
      </LogoWrapper>
      <Title>{'Get a preview and explore benefits of Saving Bucks \n for business.'}</Title>
    </Left>
  )
}

export default LeftSide
