/* eslint-disable react/no-array-index-key */
import { memo, useEffect, useLayoutEffect, useRef, useState } from 'react'
import 'swiper/css'

import { nativeScroll } from '@utils/moveTo'
import { ScrollSectionsEnum, SectionOfStickyEnum } from '@helpers/enums'
import { forShopperStaticData } from '@Components/StickyDesktop/data'
import {
  H2,
  Item,
  Titles,
  Container,
  ScrollBusiness,
  OverflowWrapper,
  TitlesSubContainer,
  GradientContainer,
  ShoppersBusiness,
  GradientContainerOverflow
} from '../styled'
import Pagination from '../Pagination'
import StickyHeader from '../Header'

interface IHandleScrollProps {
  prev?: number,
  alt?: number
}

const MobileShoppers = ({ contentType }: { contentType: SectionOfStickyEnum }) => {
  const [active, setActive] = useState<number>(0)
  const [getObserver, setGetObserver] = useState<number>(0)
  const shoppersRef = useRef(null)

  const prevHandler = () => {
    setActive((prev) => {
      if (prev === 0) return prev

      const item = document.querySelector('.mobile-shoppers-scroll-item')

      // @ts-ignore
      shoppersRef.current.scroll({
        left: !item ? 0 : (prev - 1) * item.clientWidth,
        behavior: 'smooth'
      })

      return prev - 1
    })
  }

  const nextHandler = () => {
    setActive((prev) => {
      if (prev >= 4) {
        return prev
      }

      const item = document.querySelector('.mobile-shoppers-scroll-item')

      // @ts-ignore
      shoppersRef.current.scroll({
        left: !item ? 0 : (prev + 1) * item.clientWidth,
        behavior: 'smooth'
      })

      return prev + 1
    })
  }

  const setActiveIndex = (index: number) => {
    const item = document.querySelector('.mobile-shoppers-scroll-item')

    // @ts-ignore
    shoppersRef.current.scroll({
      left: !item ? 0 : index * item.clientWidth,
      behavior: 'smooth'
    })

    setTimeout(() => {
      setActive(index)
    }, 300)
  }

  useEffect(() => {
    setActive(getObserver)
  }, [getObserver])

  useEffect(() => {
    const scrollContainer = document.querySelector('.mobile-shoppers-scroll-container')
    const scrollItems = document.querySelectorAll('.mobile-shoppers-scroll-item')

    if (!scrollContainer || !scrollItems) {
      return
    }

    const options = {
      root: scrollContainer,
      rootMargin: '0px',
      threshold: 0.3
    }

    const observer = new IntersectionObserver((entries) => {
      const viewportTarget = entries.find((entry) => entry.isIntersecting)

      if (viewportTarget) {
        const result = viewportTarget.target?.getAttribute('data-id') || 0
        setGetObserver(+result)
      }
    }, options)

    scrollItems.forEach((item) => { observer.observe(item) })
  }, [])

  return (
    <Container>
      <OverflowWrapper>
        <StickyHeader contentType={contentType} />
        <Titles>
          <TitlesSubContainer>
            <H2>
              {'Find your favourite \n deals and businesses.\n All in one place.'}
            </H2>
          </TitlesSubContainer>
        </Titles>
        <GradientContainer>
          <GradientContainerOverflow ref={shoppersRef} className='mobile-shoppers-scroll-container'>
            {forShopperStaticData.map((item, index) => {
              return (
                <div className='mobile-shoppers-scroll-item' key={index} data-id={index}>
                  <Item>
                    <img src={item.src} alt={item.name} />
                    <h3>{item.name}</h3>
                    <p>{item.description}</p>
                  </Item>
                </div>
              )
            })}
          </GradientContainerOverflow>
          <Pagination
            activeSlide={active}
            setActiveIndex={setActiveIndex}
            prevHandler={prevHandler}
            nextHandler={nextHandler}
          />
        </GradientContainer>
        <ScrollBusiness
          onClick={() => { nativeScroll(ScrollSectionsEnum.business) }}
        >
          <p>For Business</p>
          <svg xmlns='http://www.w3.org/2000/svg' width='20' height='10' viewBox='0 0 20 10' fill='none'>
            <path opacity='0.2' d='M10.8019 10H9.19811L0 1.70507L1.60377 0L10 7.5576L18.3962 0L20 1.70507L10.8019 10Z' fill='black' />
          </svg>
        </ScrollBusiness>
      </OverflowWrapper>
    </Container>
  )
}

export default memo(MobileShoppers)
