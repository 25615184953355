import styled from 'styled-components'

export const SwitcherContainer = styled.div`
  position: absolute;
  top: 38px;
  display: flex;
  align-items: start;
  justify-content: flex-start;

  span {
    margin: 10px 21px;
    height: 20px;
    width: 1px;
    background: #959ba9;
  }
`

export const Title = styled.p<{ active?: string }>`
  position: relative;
  cursor: pointer;
  z-index: 12;
  color: #959ba9;
  opacity: 0.4;
  font-size: 24px;
  line-height: 33px;
  text-align: left;
  letter-spacing: 0em;
  opacity: ${({ active }) => (active ? 1 : 0.4)};
`
