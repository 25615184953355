import { FC } from 'react'

import { ReactComponent as RightArrow } from '@assets/svg/arrow-right.svg'
import { ReactComponent as LeftArrow } from '@assets/svg/arrow-left.svg'
import { StyledPagination, SvgWrapper } from './styled'

interface PaginationProps {
  activeSlide: number
  setActiveIndex: (item: number) => void
  prevHandler: () => void
  nextHandler: () => void
}

const Pagination: FC<PaginationProps> = ({
  activeSlide,
  nextHandler,
  prevHandler,
  setActiveIndex
}) => {
  return (
    <StyledPagination>
      <SvgWrapper onClick={prevHandler}>
        <LeftArrow />
      </SvgWrapper>
      <div className='points'>
        {[0, 1, 2, 3, 4].map((item) => {
          if (item === activeSlide) {
            return (
              <div className='wrapper' key={item} onClick={() => { setActiveIndex(item) }}>
                <div className='point active' />
              </div>
            )
          }

          return (
            <div className='wrapper' key={item} onClick={() => { setActiveIndex(item) }}>
              <div className='point' />
            </div>
          )
        })}
      </div>
      <SvgWrapper onClick={nextHandler}>
        <RightArrow />
      </SvgWrapper>
    </StyledPagination>
  )
}

export default Pagination
