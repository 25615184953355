import { memo } from 'react'
import SideBar from '@Components/SideBar'
import Landing from '@Components/Landing'
import GlobalStyle from '@assets/GlobalStyle'

import ContextWrapper from './context/main'

const App = () => {
  return (
    <ContextWrapper>
      <GlobalStyle />
      <Landing />
      <SideBar />
    </ContextWrapper>
  )
}

export default memo(App)
